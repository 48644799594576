import React from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { TextField } from '@shared/components/text-field';

import { styles } from './Authorization.styles';

export interface AuthorizationProps extends WithStyles<typeof styles> {
  onBlurEmail: (email: string) => void;
  onBlurToken: (token: string) => void;
}

const AuthorizationComponent: React.FC<AuthorizationProps> = ({ classes, onBlurEmail, onBlurToken }) => {
  const [email, setEmail] = React.useState('');
  const [emailValid, setEmailValid] = React.useState(true);
  const [token, setToken] = React.useState('');

  const validateEmail = (email: string) => {
    const emailRegExp = /\S+@\S+\.\S+/;

    return emailRegExp.test(email);
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleBlurEmail = () => {
    const validEmail = validateEmail(email);

    setEmailValid(email ? validEmail : true);

    if (validEmail || !email) {
      onBlurEmail(email);
    }
  };

  const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => setToken(e.target.value);

  const handleBlurToken = () => onBlurToken(token);

  return (
    <div className={classes.root}>
      <Flex direction="column" classes={{ root: classes.content }}>
        <span className={classes.label}>Email Address</span>
        <TextField
          fullWidth
          value={email}
          onChange={handleChangeEmail}
          onBlur={handleBlurEmail}
          error={!emailValid}
          errorText="Email is not valid"
          placeholder="Email"
          classes={{
            input: classes.textFieldInput,
          }}
        />
      </Flex>
      <Flex direction="column" classes={{ root: classes.content }}>
        <span className={classes.label}>API v4 refresh token</span>
        <TextField
          fullWidth
          placeholder="Token"
          onChange={handleChangeToken}
          onBlur={handleBlurToken}
          classes={{
            input: classes.textFieldInput,
          }}
        />
      </Flex>
    </div>
  );
};

export const Authorization = withStyles(styles)(AuthorizationComponent);
