import { createStyles } from '@core/theme/utils/create-styles';

export const styles = () =>
  createStyles({
    root: {},
    content: {
      marginBottom: 15,
    },
    label: {
      marginBottom: 10,
      color: '#2a354f',
      fontSize: 16,
    },
    textFieldInput: {
      height: 35,
    },
  });
